import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.smugglerscove.net/about/"}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header
                  as={"h1"}
                  className={"about-us-header"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_header",
                    defaultValue: "ABOUT US",
                  })}
                />
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <h2>Family-Owned & Operated</h2>
                      <p>
                        Founded in 1980, and still family-owned and operated,
                        Smuggler’s Cove has been proud to serve loyal customers,
                        visitors and residents of the Mount Pocono and
                        Tannersville, PA communities for over 40 years. Ron and
                        Jenette Sarajian first opened the doors of Smuggler’s
                        Cove back in 1980, and have been welcoming lunch and
                        dinner guests ever since with their classic
                        interpretation of a casual, family-friendly surf and
                        turf restaurant located in Tannersville, PA.
                      </p>
                      <p>
                        Over the years, we have expanded to add dining rooms and
                        recently a newly expanded Castaways Lounge for our
                        guests to enjoy.Specializing in fresh seafood, steaks,
                        and prime rib, our primary focus is on the high quality
                        of seafood, fish and beef we source from some of the
                        best suppliers in the country. All of our fresh catches
                        of the day are fresh, never frozen, and are supplied to
                        us direct from wholesalers in Boston, MA three times a
                        week. All of our steaks are supplied by Allen Brothers
                        in Chicago, IL. Our menu is quite extensive, and
                        features everything from great burgers to huge Maine
                        lobster tails and the best slow-roasted prime rib you
                        can find. We offer a delicious children’s menu and a
                        large salad bar that is included with all entrees.
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
